import React, { JSX } from 'react';

type Props = {
  condition: boolean;
  wrapper: (children: JSX.Element | null) => JSX.Element | null;
  children: JSX.Element | null;
};

const ConditionalWrapper: React.FC<Props> = (props) => {
  const { condition, wrapper, children } = props;
  return condition ? wrapper(children) : children;
};

export default ConditionalWrapper;
