"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BackupConverter = exports.BackupAction = void 0;
const time_1 = require("../../Util/time");
var BackupAction;
(function (BackupAction) {
    BackupAction["CREATE"] = "CREATE";
    BackupAction["DELETE"] = "DELETE";
    BackupAction["UPDATE"] = "UPDATE";
})(BackupAction || (exports.BackupAction = BackupAction = {}));
const BackupConverter = (dataConverter) => {
    return {
        fromFirestore: (snap) => {
            const data = snap.data();
            const BackupData = {
                resourceId: data.resourceId,
                action: data.action,
                actionDate: (0, time_1.parseDateFromTimestamp)(data.actionDate),
                userId: data.userId
            };
            Object.keys(BackupData).forEach((k) => delete data[k]);
            return {
                ...BackupData,
                ...dataConverter.fromFirestore(snap)
            };
        },
        toFirestore: (data) => {
            const BackupData = {
                resourceId: data.resourceId,
                action: data.action,
                actionDate: data.actionDate,
                userId: data.userId
            };
            Object.keys(BackupData).forEach((k) => delete data[k]);
            return {
                ...BackupData,
                ...dataConverter.toFirestore(data)
            };
        }
    };
};
exports.BackupConverter = BackupConverter;
