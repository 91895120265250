"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogicalOperatorValuesAsString = exports.LogicalOperatorValues = exports.LogicalOperatorTypeKeys = exports.LogicalOperator = void 0;
exports.isLogicalOperator = isLogicalOperator;
var LogicalOperator;
(function (LogicalOperator) {
    LogicalOperator["NONE"] = "__none__";
    LogicalOperator["AND"] = "__and__";
    LogicalOperator["OR"] = "__or__";
})(LogicalOperator || (exports.LogicalOperator = LogicalOperator = {}));
exports.LogicalOperatorTypeKeys = Object.keys(LogicalOperator);
exports.LogicalOperatorValues = Object.values(LogicalOperator);
exports.LogicalOperatorValuesAsString = Object.values(LogicalOperator);
function isLogicalOperator(value) {
    return exports.LogicalOperatorValues.includes(value);
}
