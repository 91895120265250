"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeatureLocksKeys = exports.FeatureLocks = void 0;
class FeatureLocks {
    constructor(obj) {
        this.pictureTaking = false;
        if (typeof obj?.pictureTaking === "boolean") {
            this.pictureTaking = obj.pictureTaking;
        }
    }
}
exports.FeatureLocks = FeatureLocks;
exports.FeatureLocksKeys = Object.keys(new FeatureLocks());
