"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogicalBlockRequiredKeys = exports.LogicalBlockEntries = exports.LogicalBlockValues = exports.LogicalBlockKeys = exports.LogicalBlock = void 0;
exports.isLogicalBlockComplete = isLogicalBlockComplete;
const Elements_1 = require("./Elements");
const LogicalOperator_1 = require("./LogicalOperator");
class LogicalBlock extends Elements_1.ParentSpecificData {
    constructor() {
        super(...arguments);
        this.operation = LogicalOperator_1.LogicalOperator.OR;
    }
}
exports.LogicalBlock = LogicalBlock;
exports.LogicalBlockKeys = Object.keys(new LogicalBlock());
exports.LogicalBlockValues = Object.values(new LogicalBlock());
exports.LogicalBlockEntries = Object.entries(new LogicalBlock());
exports.LogicalBlockRequiredKeys = exports.LogicalBlockKeys;
function isLogicalBlockComplete(value) {
    // console.log(value);
    return (LogicalOperator_1.LogicalOperatorValues.includes(value.operation) &&
        Array.isArray(value.contentChildrenIds) &&
        value.contentChildrenIds.length > 0 &&
        (value.operation === LogicalOperator_1.LogicalOperator.AND ? !!value.outputNodeId : true));
}
