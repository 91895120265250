"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserState = exports.PictureData = void 0;
const immer_1 = require("immer");
// State
class PictureData {
    constructor() {
        // To allow usage of class instance in redux
        this[_a] = true;
        this.isLoaded = false;
        this.isLoading = false;
    }
}
exports.PictureData = PictureData;
_a = immer_1.immerable;
class UserState {
    constructor() {
        // To allow usage of class instance in redux
        this[_b] = true;
        this.isLoaded = false;
        this.uid = '';
        this.emailVerified = false;
        this.profile = {};
        this.claims = {};
        this.profilePics = {};
        this.profilePicThumbs = {};
    }
}
exports.UserState = UserState;
_b = immer_1.immerable;
