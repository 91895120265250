"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaskConverter = exports.TaskRequiredKeys = exports.TaskEntries = exports.TaskKeys = exports.Task = exports.isTaskState = exports.TaskState = void 0;
const time_1 = require("../../Util/time");
var TaskState;
(function (TaskState) {
    TaskState["PENDING"] = "PENDING";
    TaskState["STARTED"] = "STARTED";
    TaskState["UPLOADING"] = "UPLOADING";
    TaskState["COMPLETED"] = "COMPLETED";
})(TaskState || (exports.TaskState = TaskState = {}));
const isTaskState = (value) => {
    const options = Object.values(TaskState);
    return value && options.includes(value);
};
exports.isTaskState = isTaskState;
class Task {
    constructor() {
        this.addressesCount = 0;
        /** Deflated stringified version of {@link InflatedTaskAddressesData} */
        this.addressesList = '';
        this.createdDate = new Date();
        this.exampleAddresses = {
            count: 0,
            start: [],
            end: []
        };
        this.name = '';
        this.orderedColumns = [];
        this.state = TaskState.PENDING;
    }
}
exports.Task = Task;
exports.TaskKeys = Object.keys(new Task());
exports.TaskEntries = Object.entries(new Task());
exports.TaskRequiredKeys = [];
exports.TaskConverter = {
    fromFirestore: (snap) => {
        const data = snap.data();
        return { ...data, createdDate: (0, time_1.parseDateFromTimestamp)(data.createdDate) };
    },
    toFirestore: (data) => data
};
