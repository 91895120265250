"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExtraFeaturesKeys = exports.ExtraFeaturesConverter = exports.ExtraFeatures = void 0;
class ExtraFeatures {
    constructor(obj) {
        this.flightLogsAnalytics = false; // Tudo o que for relacionado a drones
        this.usageAnalytics = false; // Session history e Usage
        if (typeof obj?.flightLogsAnalytics === 'boolean') {
            this.flightLogsAnalytics = obj.flightLogsAnalytics;
        }
        if (typeof obj?.usageAnalytics === 'boolean') {
            this.usageAnalytics = obj.usageAnalytics;
        }
    }
}
exports.ExtraFeatures = ExtraFeatures;
exports.ExtraFeaturesConverter = {
    toFirestore: (data) => data,
    fromFirestore: (snap) => {
        const data = snap.data();
        return { ...data };
    }
};
exports.ExtraFeaturesKeys = Object.keys(new ExtraFeatures());
