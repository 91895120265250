"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DirectConverter = void 0;
/**
 * Instantiates direct converter, which just applies type to document data, not making any
 * actual transformation to data types
 */
const DirectConverter = () => ({
    toFirestore(data) {
        return Object.assign({}, data);
    },
    fromFirestore(snapshot) {
        return snapshot.data();
    }
});
exports.DirectConverter = DirectConverter;
