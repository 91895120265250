/** Maps columns names per data type */
export type LogHeaders = { [type: string]: string[] };
/**
 * Maps log lines per data type, each line contains a corresponding value to
 * columns described in {@link LogHeaders}
 */
export type LogData = { [type: string]: string[][] };

/** Errors that may happen while checking log data */
const MISSING_HEADER = 'MISSING_HEADER';
const UNMATCHING_HEADER_AND_DATA = 'UNMATCHING_HEADER_AND_DATA';
const errorTypes = [MISSING_HEADER, UNMATCHING_HEADER_AND_DATA] as const;
type Errors = (typeof errorTypes)[number];

/** Object to store errors from checking log data */
type ErrorMap = Map<Errors, Map<string /* header type */, number /* error count */>>;
function initErrorMap(map?: ErrorMap): ErrorMap {
  const _map = map || (new Map() as ErrorMap);
  errorTypes.forEach((errType) => _map.set(errType, new Map() as Map<string, number>));
  return _map;
}
function incrementErrorPerHeaderType(map: ErrorMap, errType: Errors, headerType: string) {
  const currTypeErrors = map.get(errType);
  currTypeErrors!.set(headerType, (currTypeErrors!.get(headerType) || 0) + 1);
}

/**
 * Checks log consistency, returning an organized data structure with log data.
 * On failure will throw exceptions on decompression or unexpected data format.
 */
export default function checkLogData(
  filename: string,
  logData: string
): [headers: LogHeaders, data: LogData, startTime: number, endTime: number] {
  // Beginning of the file contains headers. After an empty line, data is being logged.
  let readingHeaders = true;

  // Combine columns headers by data type identifier
  const headers: LogHeaders = {};
  const data: LogData = {};

  let firstEntryTime = Number.MAX_SAFE_INTEGER;
  let lastEntryTime = Number.MIN_SAFE_INTEGER;

  const dataErrors: ErrorMap = initErrorMap();
  let hadSomeDataError = false;

  const lines = logData.split('\n');
  for (const line of lines) {
    // If an empty line is found, means log data have started and header description ended.
    if (line === '') {
      readingHeaders = false;
      // console.log(Object.entries(headers).forEach(([k, v]) => `${k}: ${v?.length}`));
    }

    // Line format should be: [type_identifier ...base_fields ...column_names], tab separated.
    const splitLine = line.split('\t');
    const type = splitLine[0];
    // Removing type identifier
    const lineData = splitLine.slice(1);

    // If reading headers, accumulate columns on mapping object, per data identifier
    if (readingHeaders) {
      headers[type] = lineData;
    } // If reading log data, accumulate it in the data object, skipping empty lines
    else if (line.length) {
      // Check if data header exists
      const headerColumns = headers[type];
      if (!headerColumns) {
        incrementErrorPerHeaderType(dataErrors, MISSING_HEADER, type);
        hadSomeDataError = true;
        // console.log(
        //   type,
        //   lineData,
        //   headerColumns,
        //   Object.entries(headers).map(([k, v]) => `${k}: ${v?.length || 0}`)
        // );
        // break;
        continue;
      }

      // Check if line data count corresponds to header columns count
      const headerCount = headerColumns.length;
      const lineDataCount = lineData.length;
      if (headerCount !== lineDataCount) {
        incrementErrorPerHeaderType(dataErrors, UNMATCHING_HEADER_AND_DATA, type);
        hadSomeDataError = true;
        // console.log(lineData, headerColumns);
        // break;
        continue;
      }

      // Keep track of first and last message times
      // Index 0 of data line is always timestamp, since type id. has been removed
      const timestamp = parseInt(lineData[0]);
      firstEntryTime = Math.min(firstEntryTime, timestamp);
      lastEntryTime = Math.max(lastEntryTime, timestamp);

      // Save data by type
      if (Array.isArray(data[type])) data[type].push(lineData);
      else data[type] = [lineData];
    }
  }

  // Both headers and data must exist
  const headersSize = Object.values(headers).every((h) => h?.length > 0);
  const dataSize = Object.values(data).every((v) => v?.length > 0);
  if (!headersSize || !dataSize)
    throw Error(`Missing: headers: ${!headersSize}, valid data: ${!dataSize})`);

  // Log existence of inconsistencies
  if (hadSomeDataError) {
    console.error(
      `Logfile ${filename} contained the following errors:\n${errorTypes
        .map((errType) => {
          let err = `${errType}:\n`;
          dataErrors.get(errType)!.forEach((count, type) => (err += `\t${type}: ${count}\n`));
          return err;
        })
        .join()}`
    );
  }

  return [headers, data, firstEntryTime, lastEntryTime];
}
