import { AlertColor } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { isServerMessageJSON, ServerMessageJSON } from "flyid-core/dist/Common/messages";
import { SnackbarState } from "flyid-core/dist/Redux/types/uiTypes";

type MessageContainer<T> = AxiosResponse<T> | Error | AxiosError<T>;

const isMessageResponse = <T>(resOrErr: MessageContainer<T>): resOrErr is AxiosResponse<T> =>
  isServerMessageJSON((resOrErr as AxiosResponse<T>).data);

const isAxiosErrorWithMessage = <T>(resOrErr: MessageContainer<T>): resOrErr is AxiosError<T> =>
  isServerMessageJSON((resOrErr as AxiosError<T>)?.response?.data);

export const parseServerResponse = <T>(
  resOrErr: MessageContainer<T>
): ServerMessageJSON | string => {
  // Check if is a successsful server response with body containing ServerMessage
  if (isMessageResponse<ServerMessageJSON>(resOrErr as MessageContainer<ServerMessageJSON>)) {
    return (resOrErr as AxiosResponse<ServerMessageJSON>).data;
  } // Check if error and contains response data with body containing ServerMessageJSON
  else if (isAxiosErrorWithMessage(resOrErr)) {
    return (resOrErr as AxiosError<ServerMessageJSON>).response?.data ?? "Empty message!";
  }
  // Otherwise create Message with any error message we can find
  return (resOrErr as Error).message || "Empty message!";
};

export function getSnackbar<T = ServerMessageJSON>(resErr: MessageContainer<T>): SnackbarState {
  const success = !(resErr instanceof Error) && resErr && String(resErr.status) === "200";

  return {
    message: parseServerResponse(resErr),
    duration: success ? 3000 : 5000,
    severity: (success ? "success" : "error") as AlertColor,
    show: true
  };
}
