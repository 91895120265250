import { Route, Routes } from 'react-router-dom';
import BatteryHistory from './components/dashboard/BatteryHistory';
import FlightLogCharts from './components/dashboard/FlightLogCharts';
import SessionHistory from './components/dashboard/SessionHistory';
import UsageCounter from './components/dashboard/UsageCounter';
import ForgotPassword from './components/layouts/ForgotPassword';
import Home from './components/layouts/Home';
import Login from './components/layouts/Login';
import ProtectedRoute from './components/utils/ProtectedRoute';

const routes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route element={<ProtectedRoute />}>
        <Route path="/batteryhistory" element={<BatteryHistory />} />
        <Route path="/sessionhistory" element={<SessionHistory />} />
        <Route path="/usagecounter" element={<UsageCounter />} />
        <Route path="/flightlogcharts" element={<FlightLogCharts />} />
        {/* Main */}
        <Route path="*" element={<Home />} />
      </Route>
    </Routes>
  );
};

export default routes;
