"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.storeValue = exports.storeEventValue = exports.storeEventCheck = exports.decodeText = exports.encodeText = void 0;
exports.getStoredOrDefault = getStoredOrDefault;
const textDecoderNotSupported = 'Sorry, this system does not support TextDecoder...';
const encodeText = (str) => {
    if (!('TextDecoder' in window)) {
        console.error(textDecoderNotSupported);
        return;
    }
    return new TextEncoder().encode(str);
};
exports.encodeText = encodeText;
const decodeText = (buffer) => {
    if (!('TextDecoder' in window)) {
        console.error(textDecoderNotSupported);
        return;
    }
    return new TextDecoder().decode(buffer);
};
exports.decodeText = decodeText;
const storeEventCheck = (key, setter) => (e) => {
    localStorage.setItem(key, JSON.stringify(e.target.checked));
    setter?.(e.target.checked);
};
exports.storeEventCheck = storeEventCheck;
const storeEventValue = (key, setter) => (e) => {
    localStorage.setItem(key, JSON.stringify(e.target?.value));
    setter?.(e.target?.value);
};
exports.storeEventValue = storeEventValue;
const storeValue = (key, value, setter, converter) => {
    localStorage.setItem(key, JSON.stringify(converter?.(value) ?? value));
    setter?.(value);
};
exports.storeValue = storeValue;
function getStoredOrDefault(key, def) {
    const value = localStorage.getItem(key);
    if (
    // eslint-disable-next-line eqeqeq
    value == null ||
        (typeof def !== 'number' && value === '0') ||
        (typeof def !== 'string' && value === ''))
        return def;
    try {
        return JSON.parse(value);
    }
    catch {
        return def;
    }
}
