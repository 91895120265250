"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FlightLogConverter = void 0;
const time_1 = require("../../../Util/time");
exports.FlightLogConverter = {
    fromFirestore: (snap) => {
        const data = snap.data();
        return {
            ...data,
            createdDate: (0, time_1.parseDateFromTimestamp)(data.createdDate),
            endTime: (0, time_1.parseDateFromTimestamp)(data.endTime),
            startTime: (0, time_1.parseDateFromTimestamp)(data.startTime)
        };
    },
    toFirestore: (data) => ({
        ...data,
        createdDate: data.createdDate instanceof Date ? data.createdDate : new Date(data.createdDate),
        endTime: data.endTime instanceof Date ? data.endTime : new Date(data.endTime),
        startTime: data.startTime instanceof Date ? data.startTime : new Date(data.startTime)
    })
};
