"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceDataConverter = exports.DeviceDataRequiredKeys = exports.DeviceDataEntries = exports.DeviceDataKeys = exports.DeviceData = void 0;
const time_1 = require("../../Util/time");
class DeviceData {
    constructor() {
        // These values are received by application
        /** Device uuid is the should be the unique device id across installations for this app */
        this.deviceUuid = '';
        this.scannerName = '';
        this.scannerLicense = '';
        this.appVersion = '';
        this.lastUse = new Date();
        this.name = '';
        this.mktName = '';
        this.model = '';
        this.codename = '';
    }
}
exports.DeviceData = DeviceData;
exports.DeviceDataKeys = Object.keys(new DeviceData());
exports.DeviceDataEntries = Object.entries(new DeviceData());
exports.DeviceDataRequiredKeys = exports.DeviceDataKeys.filter((k) => k !== 'username' && k !== 'usageCount');
exports.DeviceDataConverter = {
    fromFirestore: (snap) => {
        const data = snap.data();
        return { ...data, lastUse: (0, time_1.parseDateFromTimestamp)(data.lastUse) };
    },
    toFirestore: (data) => data
};
