"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionDataConverter = void 0;
const time_1 = require("../../../Util/time");
exports.SessionDataConverter = {
    fromFirestore: (snap) => {
        const data = snap.data();
        try {
            return {
                ...data,
                startTime: (0, time_1.parseDateFromTimestamp)(data.startTime),
                endTime: (0, time_1.parseDateFromTimestamp)(data.endTime)
            };
        }
        catch (err) {
            console.error(`${data.domain} - ${snap.id} - ${snap.startTime} - ${snap.endTime}`);
            throw err;
        }
    },
    toFirestore: (data) => data
};
