"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDefaultDomainSettings = exports.DomainSettingsConverter = exports.DomainSettingsRequiredFieldsKeys = exports.DomainSettingsRequiredProcessFlowKeys = exports.DomainSettingsRequiredKeys = exports.DomainSettingsEntries = exports.DomainSettingsKeys = exports.DomainSettings = exports.FieldsSettingsEntries = exports.FieldsSettingsKeys = exports.FieldsSettings = exports.ProcessFlowSettingsEntries = exports.ProcessFlowSettingsKeys = exports.ProcessFlowSettings = exports.TypedDomainSettingFlags = exports.DomainSettingFlags = void 0;
exports.convertDomainSettingsFromFirestore = convertDomainSettingsFromFirestore;
const time_1 = require("../../../Util/time");
const BaseFields_1 = require("./BaseFields");
const TabularData_1 = require("./ProcessFlow/TabularData");
const ResultFields_1 = require("./ResultFields");
var DomainSettingFlags;
(function (DomainSettingFlags) {
    DomainSettingFlags["PROCESS_FLOW_HAS_INCONSISTENCY"] = "internal_flag_processFlowHasInconsistency";
})(DomainSettingFlags || (exports.DomainSettingFlags = DomainSettingFlags = {}));
class TypedDomainSettingFlags {
}
exports.TypedDomainSettingFlags = TypedDomainSettingFlags;
DomainSettingFlags.PROCESS_FLOW_HAS_INCONSISTENCY;
/** Currently does not require a converter, all data is built over primitives */
class ProcessFlowSettings {
    constructor() {
        this.labelDesigns = {};
        this.manualInputFields = {};
        this.picTaking = {};
        this.autoFillData = {};
        /**
         * Nested lists are not allowed in firebase, we must make type conversions on save and fetch.
         * i.e.: Array<Type[]> <-> Map<index, Type[]>
         */
        this.conditionals = {};
        this.logicalBlocks = {};
        this.customMarkers = {};
        this.nodes = {};
        this.edges = [];
    }
}
exports.ProcessFlowSettings = ProcessFlowSettings;
exports.ProcessFlowSettingsKeys = Object.keys(new ProcessFlowSettings());
exports.ProcessFlowSettingsEntries = Object.entries(new ProcessFlowSettings());
class FieldsSettings {
    constructor() {
        /** Descriptor of address pattern with RCNP* standard */
        this.addrPattern = 'RRCCNA';
        /** Descriptor of user readable address pattern with RCNP* standard */
        this.addrPatternUser = 'RR-CC-N-A';
        this.baseFields = new BaseFields_1.BaseFields();
        this.resultFields = new ResultFields_1.ResultFields();
        /** Custom markers that may be used to set custom acquisition flags */
        this.customMarkers = [];
        /** Fields that not necessarily appear on barcodes but can be AutoFilled from table data */
        this.autoFillableFields = [];
        /** Fields that come from client database inside a task creation, which may be used for autofilling */
        this.extraTaskFields = [];
    }
}
exports.FieldsSettings = FieldsSettings;
exports.FieldsSettingsKeys = Object.keys(new FieldsSettings());
exports.FieldsSettingsEntries = Object.entries(new FieldsSettings());
/** Data model that represents domain's settings */
class DomainSettings extends TypedDomainSettingFlags {
    constructor() {
        super(...arguments);
        this.createdDate = new Date();
        /** Version */
        this.version = 2;
        /** Domain fields settings */
        this.fieldSettings = new FieldsSettings();
        /** ProcessFlow-related settings */
        this.processFlow = new ProcessFlowSettings();
        /** Table data to be set by user for later use in field data autofilling or replacement */
        this.tabularData = new TabularData_1.TabularData();
    }
}
exports.DomainSettings = DomainSettings;
exports.DomainSettingsKeys = Object.keys(new DomainSettings());
exports.DomainSettingsEntries = Object.entries(new DomainSettings());
exports.DomainSettingsRequiredKeys = exports.DomainSettingsKeys;
exports.DomainSettingsRequiredProcessFlowKeys = exports.ProcessFlowSettingsKeys;
exports.DomainSettingsRequiredFieldsKeys = exports.FieldsSettingsKeys;
function convertDomainSettingsFromFirestore(data) {
    return {
        ...data,
        createdDate: (0, time_1.parseDateFromTimestamp)(data.createdDate ?? new Date()),
        tabularData: TabularData_1.TabularDataConverter.fromFirestore({
            data: () => data.tabularData
        })
    };
}
exports.DomainSettingsConverter = {
    fromFirestore: (snap) => {
        const data = snap.data();
        return convertDomainSettingsFromFirestore(data);
    },
    toFirestore: (data) => data
};
const getDefaultDomainSettings = () => {
    const rawDomainSett = new DomainSettings();
    const defaultDomainSettings = {
        createdDate: new Date(),
        version: 2,
        fieldSettings: {
            ...rawDomainSett.fieldSettings,
            addrPattern: 'RRCCNA',
            addrPatternUser: 'RR-CC-N-A',
            baseFields: {
                address: 'address',
                checkedAt: 'checkedAt',
                employeeId: 'employeeId',
                employeeName: 'employeeName'
            },
            resultFields: {
                empty: 'empty',
                invalid: 'invalid',
                negative: 'F',
                positive: 'T',
                null: ''
            }
        },
        tabularData: { ...rawDomainSett.tabularData },
        processFlow: { ...rawDomainSett.processFlow }
    };
    return defaultDomainSettings;
};
exports.getDefaultDomainSettings = getDefaultDomainSettings;
