"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyConverter = void 0;
const time_1 = require("../../Util/time");
const ExtraFeatures_1 = require("./Settings/ExtraFeatures");
exports.CompanyConverter = {
    toFirestore: (data) => data,
    fromFirestore: (snap) => {
        const data = snap.data();
        return {
            ...data,
            extraFeatures: ExtraFeatures_1.ExtraFeaturesConverter.fromFirestore({ data: () => data?.extraFeatures }),
            lastChange: (0, time_1.parseDateFromTimestamp)(data?.lastChange ?? new Date())
        };
    }
};
