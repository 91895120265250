import {
  collection,
  getCountFromServer,
  getDocs,
  query,
  Timestamp,
  where
} from 'firebase/firestore';
import { BackupAction } from 'flyid-core/dist/Database/Models/Backup';
import { Session } from 'flyid-core/dist/Database/Models/Session';
import { firestore } from '../firebase/firebase';

export type LogData = {
  ['DETAILS.appName']: string;
  ['DETAILS.appType']: string;
  ['DETAILS.appVersion']: string;
  ['DETAILS.batterySerial']: string[];
  ['DETAILS.company']: string;
  ['DETAILS.flightControllerSerial']: string[];
  ['DETAILS.userName']: string[];
  ['createdDate']: { seconds: number; nanoseconds: number };
  ['duration']: number;
  ['endTime']: number;
  ['startTime']: number;
};

// Current server is using firestore Timestamps as timestamps,
// Therefore current core sessions is not up to date, overwrite it:
export type OldSession = Omit<Session, 'startTime' | 'endTime'> & {
  startTime: Timestamp;
  endTime: Timestamp;
};

//Generics
const GetCompanies = async () => {
  const companiesRef = collection(firestore, 'companies');
  const data = await getDocs(companiesRef);
  return data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
};

//Log Related
const GetLogs = async (company: string) => {
  const logsRef = collection(firestore, 'logs', 'flightLogs', company);
  const data = await getDocs(logsRef);
  return data.docs.map((doc) => ({ data: doc.data() as LogData, id: doc.id }));
};

//Battery History Related
const GetCompaniesAnalytics = async () => {
  const companiesRef = collection(firestore, 'hardwareTracking');
  const data = await getDocs(companiesRef);
  return data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
};

const GetAllBatteriesData = async (company: string, dateRange: [Date, Date]) => {
  dateRange[1].setHours(23, 59, 59);
  const _collection = collection(firestore, 'hardwareTracking', company, 'battery');
  const ref = query(
    _collection,
    where('startTime', '>=', dateRange[0]),
    where('startTime', '<=', dateRange[1])
  );
  const data = await getDocs(ref);
  return data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
};

//Session History Related
const GetDomains = async (company: string) => {
  const domainsRef = collection(firestore, 'companies', company, 'backup');
  const data = await getDocs(domainsRef);
  return data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
};

const GetAllSessionsData = async (
  company: string,
  domain: string,
  firstDate: Date,
  secondDate: Date
) => {
  const _collection = collection(firestore, 'companies', company, 'backup', domain, 'sessions');
  const ref = query(
    _collection,
    where('startTime', '>=', new Date(firstDate)),
    where('startTime', '<=', new Date(secondDate))
  );
  const data = await getDocs(ref);
  return data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
};

//Usage Counter Related
const GetCount = async (companies: string[], dateRange?: Date[]) => {
  const actions = Object.values(BackupAction);
  const companyPromises = [] as Promise<void>[];
  const counts = {} as Record<string, number>;

  const companyCounts = {} as Record<string, Record<string, typeof counts>>;

  companies.forEach((company) => {
    companyCounts[company] = {};
    companyPromises.push(
      getDomains(company).then(async (domains) => {
        const countPromises = [] as Promise<void>[];
        domains.forEach((domain) => {
          companyCounts[company][domain.id] = {};

          const sessionsRef = collection(
            firestore,
            'companies',
            company,
            'backup',
            domain.id,
            'sessions'
          );
          const tasksRef = collection(
            firestore,
            'companies',
            company,
            'backup',
            domain.id,
            'tasks'
          );

          if (dateRange?.length) {
            actions.forEach((action) => {
              countPromises.push(
                getCountFromServer(
                  query(
                    sessionsRef,
                    where('action', '==', action),
                    where('actionDate', '>=', dateRange[0]),
                    where('actionDate', '<=', dateRange[1])
                  )
                ).then((sessionsQS) => {
                  counts[`session${action}`] = sessionsQS.data().count;
                  companyCounts[company][domain.id][`session${action}`] = sessionsQS.data().count;
                })
              );
              countPromises.push(
                getCountFromServer(
                  query(
                    tasksRef,
                    where('action', '==', action),
                    where('actionDate', '>=', dateRange[0]),
                    where('actionDate', '<=', dateRange[1])
                  )
                ).then((tasksQS) => {
                  counts[`task${action}`] = tasksQS.data().count;
                  companyCounts[company][domain.id][`task${action}`] = tasksQS.data().count;
                })
              );
            });
          } else {
            actions.forEach((action) => {
              countPromises.push(
                getCountFromServer(query(sessionsRef, where('action', '==', action))).then(
                  (sessionsQS) => {
                    counts[`session${action}`] = sessionsQS.data().count;
                    companyCounts[company][domain.id][`session${action}`] = sessionsQS.data().count;
                  }
                )
              );
              countPromises.push(
                getCountFromServer(query(tasksRef, where('action', '==', action))).then(
                  (tasksQS) => {
                    counts[`task${action}`] = tasksQS.data().count;
                    companyCounts[company][domain.id][`task${action}`] = tasksQS.data().count;
                  }
                )
              );
            });
          }
        });
        await Promise.all(countPromises);
      })
    );
  });

  await Promise.all(companyPromises);

  return companyCounts;
};

//Exports
export const getCompanies = GetCompanies;
export const getLogs = GetLogs;
export const getCompaniesAnalytics = GetCompaniesAnalytics;
export const getAllBatteriesData = GetAllBatteriesData;
export const getDomains = GetDomains;
export const getAllSessionsData = GetAllSessionsData;
export const getCount = GetCount;
