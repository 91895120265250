"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ManualActionNodeTypes = exports.ParentSpecificData = exports.EdgeTypes = exports.NodeTypeValues = exports.NodeTypeKeys = exports.NodeType = exports.FlowEdge = exports.FlowNode = exports.HandleType = void 0;
exports.getNodeTypeFromStringValue = getNodeTypeFromStringValue;
exports.getPureId = getPureId;
exports.getType = getType;
exports.getNodeTypesFromEdge = getNodeTypesFromEdge;
exports.getHandleTypesFromEdge = getHandleTypesFromEdge;
const Vertex_1 = require("./Vertex");
var HandleType;
(function (HandleType) {
    HandleType["START"] = "START";
    HandleType["SINGLE"] = "SINGLE";
    HandleType["MULTIPLE"] = "MULTIPLE";
    HandleType["END"] = "END";
})(HandleType || (exports.HandleType = HandleType = {}));
class FlowNode {
    constructor() {
        this.type = NodeType.Start;
        this.position = new Vertex_1.Vertex();
    }
}
exports.FlowNode = FlowNode;
class FlowEdge {
    constructor() {
        this.source = '';
        this.sourceHandle = '';
        this.target = '';
        this.targetHandle = '';
    }
}
exports.FlowEdge = FlowEdge;
var NodeType;
(function (NodeType) {
    NodeType["Start"] = "Start";
    NodeType["LabelDesign"] = "LabelDesign";
    NodeType["ManualInputField"] = "ManualInputField";
    NodeType["TakePicture"] = "TakePicture";
    NodeType["AutoFillData"] = "AutoFillData";
    NodeType["Conditional"] = "Conditional";
    NodeType["LogicalBlock"] = "LogicalBlock";
    NodeType["CustomMarker"] = "CustomMarker";
    NodeType["End"] = "End";
})(NodeType || (exports.NodeType = NodeType = {}));
exports.NodeTypeKeys = Object.keys(NodeType);
exports.NodeTypeValues = Object.values(NodeType);
var EdgeTypes;
(function (EdgeTypes) {
    EdgeTypes["Custom"] = "Custom";
})(EdgeTypes || (exports.EdgeTypes = EdgeTypes = {}));
/** Classes which parent a node should extendo from this */
class ParentSpecificData {
    constructor() {
        this.name = '';
        this.contentChildrenIds = [];
    }
}
exports.ParentSpecificData = ParentSpecificData;
exports.ManualActionNodeTypes = [
    NodeType.ManualInputField,
    NodeType.TakePicture,
    NodeType.CustomMarker
];
function getNodeTypeFromStringValue(value) {
    return value && exports.NodeTypeValues.includes(value) ? value : undefined;
}
const typedIdRegex = /^([^_]+)_([0-9a-z]+)$/; // ids are <Type>_<N base36 chars>
/** Get randomized part of element id (without element type) */
function getPureId(elementId) {
    return elementId?.match(typedIdRegex)?.[2] ?? elementId;
}
/**
 * This method returns the type of the given element id, whether node or handle.
 * It expects the id to be in the format '&lt;type&gt;_&lt;id&gt;'
 */
function getType(elementId) {
    return elementId?.match(typedIdRegex)?.[1];
}
function getNodeTypesFromEdge(edge) {
    const sourceType = getType(edge.source);
    const targetType = getType(edge.target);
    return [sourceType, targetType];
}
function getHandleTypesFromEdge(edge) {
    const sourceType = getType(edge.sourceHandle);
    const targetType = getType(edge.targetHandle);
    return [sourceType, targetType];
}
