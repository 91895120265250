//Export Data to Excel Function
import * as XLSX from "xlsx";

const DownloadExcel = (data: Array<unknown>) => {
  const workSheet = XLSX.utils.json_to_sheet(data);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, "Data");
  XLSX.writeFile(workBook, "Data.xlsx");
};

export const downloadExcel = DownloadExcel;
