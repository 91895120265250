import Dexie from 'dexie';
import { getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import { FlightLog } from 'flyid-core/dist/Database/Models/HardwareTracking/FlightLogs';
import { getFlightLogsCol } from 'flyid-core/dist/Util/database';
import { buildCollectionRef } from 'src/firebase/firestore';

class LocalStorage extends Dexie {
  logs!: Dexie.Table<Log, string>;

  constructor(dbName: string) {
    super(dbName);
    this.version(1).stores({
      logs: 'id, data'
    });
  }
}

export interface Log {
  id: string;
  logData: FlightLog;
}

export const db = new LocalStorage('pid-analytics');

export function clearData() {
  Dexie.delete('pid-analytics').catch(console.error);
}

export async function fetchFlightLogs(company: string, dateRange: [Date, Date]) {
  try {
    const count = await db.logs.count();

    if (count === 0) {
      const logQuery = query(
        buildCollectionRef(getFlightLogsCol(company)),
        where('createdDate', '>=', dateRange[0]),
        where('createdDate', '<=', dateRange[1]),
        orderBy('createdDate', 'desc'),
        limit(20)
      );
      const snapshot = await getDocs(logQuery);

      await Promise.all(
        snapshot.docs.map(async (doc) => db.logs.add({ id: doc.id, logData: doc.data() }))
      );
    } else {
      await db.logs.clear().then(async () => {
        const logQuery = query(
          buildCollectionRef(getFlightLogsCol(company)),
          where('createdDate', '>=', dateRange[0]),
          where('createdDate', '<=', dateRange[1]),
          orderBy('createdDate', 'desc'),
          limit(20)
        );
        const snapshot = await getDocs(logQuery);

        await Promise.all(
          snapshot.docs.map(async (doc) => db.logs.add({ id: doc.id, logData: doc.data() }))
        );
      });
    }
  } catch (err) {
    console.log(`Error`, err);
  }
}
