"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmptiableResultFields = exports.ResultFieldsRequiredKeys = exports.ResultFieldsEntries = exports.ResultFieldsKeys = exports.ResultFields = void 0;
/**
 * Map of result column values for each of the following acquisition cases
 * These following are the default values, which may be customized by the user on domain settings.
 */
class ResultFields {
    constructor() {
        /** Position is empty */
        this.empty = 'empty';
        /** Used when its not possible to read barcode or barcode is missing */
        this.invalid = 'invalid';
        /** Positive boolean dialog answer */
        this.positive = 'V';
        /** Negative boolean dialog answer */
        this.negative = 'F';
        /** Empty field (may be missing from label or simply not acquired) */
        this.null = '';
    }
}
exports.ResultFields = ResultFields;
exports.ResultFieldsKeys = Object.keys(new ResultFields());
exports.ResultFieldsEntries = Object.entries(new ResultFields());
exports.ResultFieldsRequiredKeys = exports.ResultFieldsKeys;
exports.EmptiableResultFields = ['null'];
