import dayjs, { Dayjs } from 'dayjs';
import { StoredStateConverter } from 'flyid-ui-components/dist/converters/storedStateConverters';

export const DayJSConverter: StoredStateConverter<Dayjs> = {
  fromStorage: (data) => (data ? dayjs(data) : undefined),
  toStorage: (data) => {
    if (data) {
      try {
        return data.toISOString();
      } catch {
        return undefined;
      }
    }
    return undefined;
  }
};
