import { CircularProgress } from '@mui/material';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Outlet, RouteProps, useNavigate } from 'react-router-dom';
import { auth } from 'src/firebase/firebase';
import { useAppSelector } from 'src/hooks/reduxHooks';
import { isModeratorProf } from '../../util/helpers/user';

type ProtectedRouteProps = RouteProps & {
  modOnly?: boolean;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ modOnly }) => {
  const navigate = useNavigate();
  const [authUser, authLoading] = useAuthState(auth);
  const { userData } = useAppSelector(({ user, locale }) => ({
    userData: user,
    locale: locale.locale
  }));
  const { profile, isLoaded: isProfileLoaded } = userData;

  const signedIn = Boolean(!authLoading && authUser);
  const isLoginPreparing = authLoading || !isProfileLoaded;

  // Loading authentication or profile data
  if (isLoginPreparing) return <CircularProgress />;
  else if (signedIn) {
    if (modOnly && !isModeratorProf(profile)) navigate('/');
  } else navigate('/login');

  return <Outlet />;
};

export default ProtectedRoute;
