"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUiSlice = getUiSlice;
const toolkit_1 = require("@reduxjs/toolkit");
const uiTypes_1 = require("../types/uiTypes");
function getUiSlice(initialLoadingButtonsState) {
    // Classe especializada
    const CustomUiState = (uiTypes_1.UiState);
    const StateKeys = Object.keys(new CustomUiState({ ...initialLoadingButtonsState }));
    const uiSlice = (0, toolkit_1.createSlice)({
        name: "ui",
        initialState: new CustomUiState({ ...initialLoadingButtonsState }),
        reducers: {
            updateUi: (state, action) => {
                const payload = action.payload;
                if (payload) {
                    Object.assign(state, new CustomUiState({ ...initialLoadingButtonsState }));
                    for (const type of StateKeys) {
                        if (payload[type])
                            Object.assign(state[type], payload[type]);
                    }
                }
                else {
                    state.snackbar.show = false;
                    state.dialog.show = false;
                    state.backdrop.show = false;
                    state.loadingButton = { ...state.loadingButton, ...initialLoadingButtonsState };
                    state.imageViewer.show = false;
                }
            },
            updateUiNoReset: (state, action) => {
                const payload = action.payload;
                if (payload) {
                    for (const type of StateKeys) {
                        if (payload[type])
                            Object.assign(state[type], payload[type]);
                    }
                }
            }
        }
    });
    return {
        actions: uiSlice.actions,
        reducer: uiSlice.reducer
    };
}
