const productionV1Config = {
  apiKey: 'AIzaSyAW_UIz1c0AnohViFTFvOkylHXAhr9Pwss',
  authDomain: 'pid-flyid.firebaseapp.com',
  databaseURL: 'https://pid-flyid.firebaseio.com',
  projectId: 'pid-flyid',
  storageBucket: 'pid-flyid.appspot.com',
  messagingSenderId: '1095201324812',
  appId: '1:1095201324812:web:180be206c1f9bde4a61329',
  measurementId: 'G-T3E23EMXLY',
  // Custom keys:
  flightLogsBucket: 'flyid-flight-logs',
  profilePicsBucket: '',
  firestoreBackupBucket: ''
};
const productionConfig = {
  apiKey: 'AIzaSyB5w-qJJyxQD-lhtESw-zsLwmbvuVlThlM',
  authDomain: 'flyid-v2.firebaseapp.com',
  databaseURL: 'https://flyid-v2.firebaseio.com',
  projectId: 'flyid-v2',
  storageBucket: 'flyid-v2.appspot.com',
  messagingSenderId: '941688905293',
  appId: '1:941688905293:web:a45644a4e61c6768b58604',
  measurementId: 'G-60BP28F7HS',
  // Custom keys:
  flightLogsBucket: 'flight-logs-v2',
  profilePicsBucket: 'flyid-profilepics-v2',
  firestoreBackupBucket: 'flyid-backup-v2'
};
export type FirebaseConfig = typeof productionConfig;

const stagingV1Config: FirebaseConfig = {
  apiKey: 'AIzaSyCrpkP5ZLVtMqU7Kc87OmxkeR0WN1YoEts',
  authDomain: 'flyid-staging.firebaseapp.com',
  databaseURL: 'https://flyid-staging.firebaseio.com',
  projectId: 'flyid-staging',
  storageBucket: 'flyid-staging.appspot.com',
  messagingSenderId: '875691399627',
  appId: '1:875691399627:web:428abc1fa3d3e710963e4e',
  measurementId: 'G-B0KGWK6HCG',
  // Custom keys:
  flightLogsBucket: 'flyid-staging-flight-logs',
  profilePicsBucket: '',
  firestoreBackupBucket: ''
};
const stagingConfig: FirebaseConfig = {
  apiKey: 'AIzaSyBKKMwBt4_OMzyvcZ65nYu7PW1is3Pu9BA',
  authDomain: 'flyid-staging-v2-4376c.firebaseapp.com',
  databaseURL: 'https://flyid-staging-v2-4376c.firebaseio.com',
  projectId: 'flyid-staging-v2-4376c',
  storageBucket: 'flyid-staging-v2-4376c.appspot.com',
  messagingSenderId: '140147989563',
  appId: '1:140147989563:web:98fe3b3be6159a077a501e',
  measurementId: 'G-PMYYBC2BC1',
  // Custom keys:
  flightLogsBucket: 'flight-logs-staging-v2',
  profilePicsBucket: 'flyid-profilepics-staging-v2',
  firestoreBackupBucket: 'flyid-backup-staging-v2'
};

export { productionConfig, productionV1Config, stagingConfig, stagingV1Config };
